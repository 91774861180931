div.connected-users li {
    display: block;
    overflow: auto;
    margin: 0 10px;
    border-top: 1px solid #efefef;
    padding: 10px 0 7px;
}
div.connected-users li:first-child {
    border-top: none;
}
#connectionsPicker {
    margin: 7px;
}

div.connected-users li>span.removeConnection {
    cursor: pointer;
    float: right;
    display: block;
    /* top: 50%; */
    /* right: 0%; */
    padding: 12px 6px;
}

.connected-users .j-avatar {
    float:left;
    margin: 0 5px 0 10px;
}

.connected-users .jive-username-link {
    padding: 10px;
    line-height: 20px;
    display: block;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 120px);
}

.j-td-name a.jive-username-link.jiveTT-hover-user {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* max-width: calc(100% - 180px); */
    width: 220px;
}


.connected-users-people-picker input#connectionsPicker {
    display:none !important;
}
.people-i-work-with-show-more {
    display: block;
    cursor: pointer;
}
.connected-users-people-picker .j-select-people, .people-i-work-with-show-more {
    margin: 0 0 10px 20px;
}
.connected-users-people-picker a.jive-chooser-browse.j-select-people.j-btn-global:after {
    padding: 0 10px;
}
.connected-users .j-result-list {
    display:none !important;
}
.connected-users-people-picker .j-result-list {
    display:none !important;
}
.connected-users li.connected-user-hidden {
    display: none;
}
.connected-users-people-picker .jive-warn-box {
    display: none!important;
}